/*
 * @Author:
 * @Date: 2023-04-02 16:50:25
 * @LastEditTime: 2023-04-02 19:31:14
 * @LastEditors:
 * @Description:
 * @FilePath: \edub_tob_pc\src\api\myOp.js
 *
 */
import request from '@/common/utils/request.js'

/* 获取作业列表 */
function getStudentWorkList(pageSize, pageNum, status) {
  return request({
    url: '/stu/stuWorkManage/getStudentWorkList',
    method: 'GET',
    params: {
      //  1知识套餐
      pageSize: pageSize,
      pageNum: pageNum,
      // 学生id
      // 作业提交状态
      status: status
    }
  })
}

/* 提交作业 */
function saveWork(data) {
  return request({
    url: '/stu/stuWorkManage/saveWork',
    method: 'POST',
    data
  })
}

/* 提交学生答疑 */
function submitAskQuestion(data) {
  return request({
    url: '/stu/workAsk/submitAskQuestion',
    method: 'POST',
    data
  })
}

/* 保存作业 */
function submitWork(data) {
  return request({
    url: '/stu/stuWorkManage/submitWork',
    method: 'POST',
    data
  })
}

/* 获取作业详情 */
function getStudentWorkDetail(workId) {

  return request({
    url: '/stu//stuWorkManage/getStudentWorkDetail',
    method: 'GET',
    params: {
      // 作业ID
      workId: workId
    }
  })
}

export {
  getStudentWorkList,
  submitWork,
  getStudentWorkDetail,
  saveWork,
  submitAskQuestion
}
