import request from '@/common/utils/request.js'
import {
  WebClient
} from '@/api/webClient'
const webClient = new WebClient()
export class Evaluation extends WebClient {
  /* --------------评价 ------------------------------------------------------ */
  /** 评价列表
   *isCheck 审核状态1 审核   0 未审核
   * 课程类型  1知识套餐  班型 2直播课程  3点播课程 4公开课程 5资料包 6 试卷包 7：音频 8：图文 9：专栏  11：训练营期 12 试卷
   */
   async getStudentEvaluateList(
    pageSize,
    pageNum,
    type,
    typeId,
    isCheck,
    content,
    startTime,
    endTime,
    tenantId,
    classId
  ) {
    let params = this.combin({
      pageSize: pageSize,
      pageNum: pageNum
    }, {
      type: type,
      typeId: typeId,
      content: content,
      isCheck: isCheck,
      startTime: startTime,
      endTime: endTime,
      tenantId: tenantId,
      classId: classId
    });
    let data = await request({
      url: `/kecheng/stuEvaluate/getStudentEvaluateList`,
      method: "GET",
      params: params,
    });
    webClient.functionNo(data);
    return data;
  }
  /** 添加
   *isCheck 审核状态1 审核   0 未审核
   * 课程类型  1知识套餐  班型 2直播课程  3点播课程 4公开课程 5资料包 6 试卷包 7：音频 8：图文 9：专栏  11：训练营期 12 试卷
   */
  async addStudentEvaluate(
    type,
    typeId,
    userId,
    name,
    content,
    userName,
    userImg,
    userMobile,
    teacherId,
    classId
  ) {
    const data = await request({
      url: `/kecheng/stuEvaluate/addStudentEvaluate`,
      method: 'POST',
      params: {
        source: 'PC端',
        type: type,
        typeId: typeId,
        userId: userId,
        name: name,
        content: content,
        userName: userName,
        userImg: userImg,
        userMobile: userMobile,
        teacherId: teacherId,
        classId: classId
      }
    })
    webClient.functionThen(data)
    return data
  }
  /* --------------评分  ------------------------------------------------------ */
  /** 评价列表
   *isCheck 审核状态1 审核   0 未审核
   * 课程类型  1知识套餐  班型 2直播课程  3点播课程 4公开课程 5资料包 6 试卷包 7：音频 8：图文 9：专栏  11：训练营期 12 试卷
   */
  async getPresentScore(type, typeId, chapterId, sectionId) {
    const params = this.combin({}, {
      type: type,
      typeId: typeId,
      chapterId: chapterId,
      sectionId: sectionId
    })
    const data = await request({
      url: `/kecheng/stuScore/getPresentScore`,
      method: 'GET',
      params: params
    })
    webClient.functionNo(data)
    return data
  }
  async getPresentScorePage(pageSize, pageNum, type, typeId, chapterId, sectionId) {
    const params = this.combin({
      pageSize: pageSize,
      pageNum: pageNum
    }, {
      type: type,
      typeId: typeId,
      chapterId: chapterId,
      sectionId: sectionId
    })
    const data = await request({
      url: `/kecheng/stuScore/getPresentScorePage`,
      method: 'GET',
      params: params
    })
    webClient.functionNo(data)
    return data
  }
  /** 添加评分
   *isCheck 审核状态1 审核   0 未审核
   * 课程类型  1知识套餐  班型 2直播课程  3点播课程 4公开课程 5资料包 6 试卷包 7：音频 8：图文 9：专栏  11：训练营期 12 试卷
   */
  async addStudentScore(
    type,
    typeId,
    userId,
    name,
    content,
    score,
    userName,
    userImg,
    userMobile,
    chapterId,
    sectionId
  ) {
    const data = await request({
      url: `/kecheng/stuScore/addStudentScore`,
      method: 'POST',
      params: {
        source: 'PC端',
        type: type,
        score: score,
        typeId: typeId,
        userId: userId,
        name: name,
        content: content,
        userName: userName,
        userImg: userImg,
        userMobile: userMobile,
        chapterId: chapterId || sectionId,
        sectionId: sectionId
      }
    })
    webClient.functionThen(data)
    return data
  }

  /**
   * 评分维度
   * @param type 2 绩效
   * @param status  1 教研评分 2日常考核  3 配合度评分 4学生评分
   * @returns
   */
  async selectDimensionStuList(params) {
    const res = await request({
      url: `/lecturer/dimension/selectDimensionStuList`,
      method: 'GET',
      params
    })
    return res
  }

  /**
   * 学生提交评分
   * @param terId 课程老师id
   * @param type  1 直播 2录播
   * @param courseId  辅导课程id
   * @param chapterId  （直播章id）  （录播 章id）
   * @param sectionId  （录播 节id）
   * @param stuId  学生id
   * @param scoreContent  维度数据 [ {title: score: 1-9 remark: 反馈意见} ]
   * @param scoreNum  分数 （每维度评分*10*维度占比）总和
   * @param suggest  建议
   * @param source  来源
   * @param stuName  学生昵称
   * @param stuImg	  学生头像
   * @param stuAccount  学生 手机号  没有手机号 就取学生账号
   * @returns
   */
  async addPcTeacherScore(data) {
    const res = await request({
      url: `/kecheng/stuTeacherScore/addPcTeacherScore`,
      method: 'POST',
      data
    })
    return res
  }

  /* --------------视频答疑 ------------------------------------------------------ */
  /** 视频答疑
   *status 	1直播 2录播  3 音频 4试卷 5图文 6面授
   * type	 1课程  2试卷
   */
  async selectStuQuestionsList(
    pageSize,
    pageNum,
    type,
    courseId,
    status,
    chapterId,
    replyId,
    stuId
  ) {
    const params = this.combin({
      pageSize: pageSize,
      pageNum: pageNum
    }, {
      type: type,
      courseId: courseId,
      status: status,
      chapterId: chapterId,
      replyId: replyId,
      stuId: stuId
    })
    const data = await request({
      url: `/kecheng/questions/selectStuQuestionsList`,
      method: 'GET',
      params: params
    })
    webClient.functionNo(data)
    return data
  }
  /** 添加  */
  async saveQuestions(
    type,
    status,
    courseId,
    chapterId,
    account,
    stuImg,
    content,
    img
  ) {
    const data = await request({
      url: `/kecheng/questions/saveQuestions`,
      method: 'POST',
      data: {
        port: 'PC端',
        type: type,
        status: status,
        stuImg: stuImg,
        account: account,
        content: content,
        img: img,
        courseId: courseId,
        chapterId: chapterId
      }
    })
    webClient.functionThen(data)
    return data
  }
  /** 学生回复答疑
   * status:1:teacher  2：student
   */
  async replyStuQuestions(questionsId, replyUserImg, replyContent, replyImg) {
    const data = await request({
      url: `/kecheng/questions/replyStuQuestions`,
      method: 'POST',
      data: {
        questionsId: questionsId,
        replyUserImg: replyUserImg,
        replyContent: replyContent,
        replyImg: replyImg
      }
    })
    webClient.functionThen(data)
    return data
  }
}
